import { React, Component } from "react";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import "assets/Css-Own/adjustment.css";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { Row, Table, Col, CardBody, Card } from "reactstrap";

const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api/",
});

class Map extends Component {
  constructor(props) {
    super(props);
    api
      .get("check_in_today")
      .then((res) => {
        this.setState({ employee: res.data });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
    api
      .get("dep")
      .then((res) => {
        this.setState({ department: res.data });
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  state = {
    employee: [],
    department: [],
  };
  render() {
    const emp = this.state.employee;
    // console.log(this.state.department[0]);
    const centeredDepartment = Object(this.state.department[0]);
    console.log("test");
    console.log(centeredDepartment.lat);
    return (
      <>
        <div className="content">
          <div className="container-fluid">
            <div></div>
            <Row>
              <Col>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  //   center={{ lat: 31.2282494, lng: 29.9506433 }}
                  center={{
                    lat: parseFloat(centeredDepartment.lat),
                    lng: parseFloat(centeredDepartment.lng),
                  }}
                  zoom={15}
                  onLoad={this.onLoad}
                  onUnmount={this.onUnmount}
                >
                  {this.state.employee.map((employee) => {
                    return (
                      <Marker
                        key={employee.id}
                        position={{
                          lat: parseFloat(employee.lat),
                          lng: parseFloat(employee.lng),
                        }}
                        title={employee.employee.name}
                      />
                    );
                  })}
                  {this.state.department.map((department) => {
                    return (
                      <Circle
                        radius={parseInt(department.radius)}
                        center={{
                          lat: parseFloat(department.lat),
                          lng: parseFloat(department.lng),
                        }}
                      />
                    );
                  })}
                </GoogleMap>
              </Col>
              <Col>
                {" "}
                <Card>
                  <CardBody>
                    {emp !== 0 ? (
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th>Name</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Out of zone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.employee.map((employees) => {
                            if (employees.length === 0) {
                              return <p>No one came today</p>;
                            } else {
                              return (
                                <tr>
                                  <td>
                                    <Link
                                      to={{
                                        pathname: "/user",
                                        state: {
                                          id: employees.employee.id,
                                          email: employees.employee.email,
                                          name: employees.employee.name,
                                          gender: employees.employee.gender,
                                          position: employees.employee.position,
                                          phone: employees.employee.phone,
                                          is_Here: employees.employee.is_Here,
                                          created_at:
                                            employees.employee.created_at,
                                        },
                                      }}
                                    >
                                      {employees.employee.name}
                                    </Link>{" "}
                                  </td>
                                  <td>{employees.Start_time} </td>
                                  <td>
                                    {employees.End_time === null
                                      ? "Not Finished"
                                      : employees.End_time}
                                  </td>
                                  <td>
                                    {employees.Out_of_zone.toString() === "0"
                                      ? "Inside"
                                      : "Outside"}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <p> No one came today</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
const containerStyle = {
  width: "700px",
  height: "600px",
  //   marginLeft: '100px',
  //   marginTop: '100px',
};

export default Map;
