import { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "assets/Css-Own/login.css";
import Frame from "assets/img/frame2.png";
import logo from "assets/img/Group 8.png";
import React from "react";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }
  state = {
    email: "",
    password: "",
    admin: [],
    redirect: false,
  };
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const api = axios.create({
      baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
    });
    api
      .post("/admin/login", {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ admin: res.data, redirect: true });
        // alert("Employee added to the list.");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("Invalid Email or Password.");
      });
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/dashboard",
            state: { admin: this.state.admin },
            // data: { admin: this.state.admin },
          }}
        />
      );
    }
    return (
      <>
        <div className="row desktop">
          <div className="col" style={{ height: "100vh" }}>
            <div className="column">
              <div className="center">
                <img src={logo} alt="img" className="desktop-logo" />
                <h3>
                  Welcome to Metatracker <br /> Sign in to your admin panel{" "}
                </h3>
              </div>
              <div className="form-size">
                <Form className="form">
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      className="input"
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="example@example.com"
                      onChange={this.handleEmailChange}
                      value={this.state.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <Input
                      className="input"
                      type="password"
                      name="password"
                      id="examplePassword"
                      placeholder="********"
                      onChange={this.handlePasswordChange}
                      value={this.state.password}
                    />
                  </FormGroup>

                  <Button className="button" onClick={this.handleSubmit}>
                    Login
                  </Button>
                </Form>
              </div>
            </div>
          </div>

          <div className="col">
            <img src={Frame} alt="img" className="image-size" />
          </div>
        </div>

        <div className="row mobile">
          <div className="col center-text">
            <img className="logo" src={logo} alt="Metatracker" />
            <h5>
              <b>
                Welcome to Metatracker <br /> Sign in to your admin panel{" "}
              </b>{" "}
            </h5>
          </div>

          <div className="padding">
            <Form className="form">
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="example@example.com"
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="examplePassword"
                  placeholder="********"
                  onChange={this.handlePasswordChange}
                  value={this.state.password}
                />
              </FormGroup>
              <Button onClick={this.handleSubmit}>Login</Button>
            </Form>
          </div>
        </div>
        {/* <PopUpMarker/> */}
      </>
    );
  }
}

export default Login;
