import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Login from "views/Login";
import AdminLayout from "layouts/Admin.js";
import UserPage from "views/User.js";
import Users from "views/Users.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Login/> */}
      {/* <Route exact path="/" component={Login} /> */}
      <Route path="/login" render={(props) => <Login {...props} />} />
      {/* <Redirect to='/login' /> */}
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/user-page" render={(props) => <UserPage {...props} />} />
      <Route path="/user" render={(props) => <Users {...props} />} />
      <Redirect to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
