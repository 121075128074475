import React, { Component } from "react";
import axios from "axios";
// import 'bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import { Input } from "reactstrap";
import "assets/Css-Own/adjustment.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
});
class Tables extends Component {
  constructor(props) {
    super(props);
    api.get("/emp-dep").then((res) => {
      console.log(res.data);
      this.setState({ employees: res.data });
    });
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
  }

  handleUpdateChange(id) {
    console.log(id);
  }
  state = {
    employees: [],
  };

  handleClick = (id) => {
    window.open("/admin/User");
    //  href="admin/user"
    // <Link to="/admin/User/"></Link>
    //  this.props.history.push("admin/User.js");
    // window.location.reload(false);
  };

  handleClick = (id) => {
    // this.props.history.push("/admin/update/"+id);
    api.delete("/employees/" + id).then((res) => {
      console.log("done");
      window.location.reload(false);
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Employee Table</CardTitle>
                </CardHeader>
                {/* <div>
                <Input icon='search'
                placeholder='Search...'
                // onChange={() => searchItems()}
                />
              </div> */}
                <CardBody>
                  <Table striped>
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Department</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.employees.map((employees) => {
                        return (
                          <tr>
                            <td>{employees.name}</td>
                            <td>{employees.email}</td>
                            <td>{employees.phone}</td>
                            <td>{employees.dep_name}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/user",
                                  state: {
                                    // infoId: info.id,
                                    id: employees.id,
                                    email: employees.email,
                                    name: employees.name,
                                    gender: employees.gender,
                                    position: employees.position,
                                    phone: employees.phone,
                                    is_Here: employees.is_Here,
                                    created_at: employees.created_at,
                                  },
                                }}
                              >
                                View details
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Tables;
