// import Dashboard from "views/Dashboard.js";
// import Notifications from "views/Notifications.js";
// import Icons from "views/Icons.js";
// import Typography from "views/Typography.js";
// import TableList from "views/Tables.js";
// import Maps from "views/Map.js";
// import UserPage from "views/User.js";
// import CreateEmployee from "views/CreateEmployee";
// import DeleteEmployee from "views/DeleteEmployee";

 

// var routes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     icon: "nc-icon nc-bank",
//     component: Dashboard,
//     layout: "/admin",
//   },

//   {
//     // path: "/delete-employee",
//     name: "Admin List",
//     icon: "nc-icon nc-single-copy-04",
//     // component: DeleteEmployee,
//     // layout: "/admin",
//   },
//   {
//     path: "/user-page",
//     name: "Admin Profile",
//     icon: "nc-icon nc-single-02",
//     // component: UserPage,
//     layout: "/admin",
//   },
//   // {
//   //   path: "/icons",
//   //   name: "Icons",
//   //   icon: "nc-icon nc-diamond",
//   //   component: Icons,
//   //   layout: "/admin",
//   // },
//   {
//     path: "/notifications",
//     name: "Notifications",
//     icon: "nc-icon nc-bell-55",
//     // component: Notifications,
//     layout: "/admin",
//   },
//   {
//     // path: "/notifications",
//     name: "History log",
//     icon: "nc-icon nc-bell-55",
//     // component: Notifications,
//     layout: "/admin",
//   },
//   {
//     // path: "/notifications",
//     name: "Holiday Calendar",
//     icon: "nc-icon nc-bell-55",
//     // component: Notifications,
//     layout: "/admin",
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "nc-icon nc-pin-3",
//     component: Maps,
//     layout: "/admin",
//   },
//   {
//     path: "/typography",
//     name: "announcement",
//     icon: "nc-icon nc-chat-33",
//     component: Typography,
//     layout: "/admin",
//   },
//   {
//     // path: "/typography",
//     name: "Department list",
//     icon: "nc-icon nc-chat-33",
//     component: Typography,
//     layout: "/admin",
//   },
//   {
//     path: "/tables",
//     name: "Employee List",
//     icon: "nc-icon nc-tile-56",
//     component: TableList,
//     layout: "/admin",
//   },
  
//   {
//     path: "/create-employee",
//     name: "Create Employee",
//     icon: "nc-icon nc-single-copy-04",
//     component: CreateEmployee,
//     layout: "/admin",
//   },

//   {
//     path: "/delete-employee",
//     name: "Delete Employee",
//     icon: "nc-icon nc-single-copy-04",
//     component: DeleteEmployee,
//     layout: "/admin",
//   },






//   // {
//   //   path: "/Sendmessage",
//   //   name: "send message", 
//   //   icon: "nc-icon nc-send4",
//   //   component: Sendmessage,
//   //   layout: "/admin",
//   // },
// // {
// //     path: "/create-employee",
// //     name: "Create Employee",
// //     icon: "nc-icon nc-single-copy-04",
// //     component: CreateEmployee,
// //     layout: "/admin",
// //   },
// //   {
// //     pro: true,
// //     path: "/upgrade",
// //     name: "Create Employee",
// //     icon: "nc-icon nc-spaceship",
// //     component: UpgradeToPro,
// //     layout: "/admin",
// //   },
  
// ];
// export default routes;


// --------------------------------------------------------



import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Typography from "views/SendMessage.js";
import TableList from "views/Tables.js";
import Maps from "views/Map.js";
import CreateEmployee from "views/CreateEmployee";
import DeleteEmployee from "views/DeleteEmployee";
import AddAdmin from "views/AddAdmin";
import DepartmentTable from "views/DepartmentTable";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "nc-icon nc-diamond",
//     component: Icons,
//     layout: "/admin",
//   },
  {
    path: "/maps",
    name: "Employees Map",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    layout: "/admin",
  },
//   {
//     path: "/notifications",
//     name: "Notifications",
//     icon: "nc-icon nc-bell-55",
//     component: Notifications,
//     layout: "/admin",
//   },
//   {
//     path: "/user-page",
//     name: "User Profile",
//     icon: "nc-icon nc-single-02",
//     component: UserPage,
//     layout: "/admin",
//   },
  {
    path: "/tables",
    name: "Employee List",
    icon: "nc-icon nc-tile-56",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/Department-tables",
    name: "Department List",
    icon: "nc-icon nc-tile-56",
    component: DepartmentTable,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "send message",
    icon: "nc-icon nc-chat-33",
    component: Typography,
    layout: "/admin",
  },
    {
    path: "/create-employee",
    name: "Create Employee",
    icon: "nc-icon nc-single-copy-04",
    component: CreateEmployee,
    layout: "/admin",
  },

//   {
//     path: "/delete-employee",
//     name: "Delete Employee",
//     icon: "nc-icon nc-single-copy-04",
//     component: DeleteEmployee,
//     layout: "/admin",
//   },
//   {
//     path: "/AddAdmin",
//     name: "Create Admin ",
//     icon: "nc-icon nc-single-02",
//     component: AddAdmin,
//     layout: "/admin",
//   },

  // {
  //   path: "/Sendmessage",
  //   name: "send message", 
  //   icon: "nc-icon nc-send4",
  //   component: Sendmessage,
  //   layout: "/admin",
  // },
// {
//     path: "/Loginss",
//     name: "log in",
//     icon: "nc-icon nc-single-copy-04",
//     component: Loginss,
//     layout: "/admin",
//   },
//   {
//     pro: true,
//     path: "/upgrade",
//     name: "Create Employee",
//     icon: "nc-icon nc-spaceship",
//     component: UpgradeToPro,
//     layout: "/admin",
//   },
  
];
export default routes;
