import { React, Component } from "react";
import { Line, Pie } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";

import axios from "axios";

const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    api.get("/number-of-employees").then((res) => {
      console.log(res.data);
      this.setState({ employees: res.data });
    });
    //TODO
    api.get("/count-dep").then((res) => {
      this.setState({ department: res.data });
    });
    api.get("/count-out").then((res) => {
      this.setState({ employeesOut: res.data });
    });
    api.get("/count-in").then((res) => {
      this.setState({ employeesIn: res.data });
    });
    api.get("/absence_today").then((res) => {
      this.setState({ absence_today: res.data });
    });
    api.get("/calcIn").then((res) => {
      this.setState({ statsIn: res.data });
    });
  }
  state = {
    employees: [],
    department: 0,
    employeesOut: 0,
    employeesIn: 0,
    absence_today: 0,
    statsIn: 0,
  };

  render() {
    // console.log(this.state.statsIn[0]);
    const data = {
      labels: [1, 2, 3],
      datasets: [
        {
          label: "Out Of zone",
          pointRadius: 10,
          pointHoverRadius: 0,
          backgroundColor: ["#e3e3e3", "#4acccd", "#fcc468", "#ef8157"],
          borderWidth: 0,
          data: [
            this.state.absence_today,
            this.state.employeesIn.length,
            // 1,
            0,
            this.state.employeesOut.length,
          ],
        },
      ],
    };

    const lineData = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          data: [
            this.state.statsIn[0],
            this.state.statsIn[1],
            this.state.statsIn[2],
            this.state.statsIn[3],
            this.state.statsIn[4],
            parseInt(this.state.statsIn[5]),
            this.state.statsIn[6],
            this.state.statsIn[7],
            this.state.statsIn[8],
            this.state.statsIn[9],
            this.state.statsIn[10],
            this.state.statsIn[11],
          ],
          fill: true,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
        {
          data: [
            this.state.statsIn[0],
            this.state.statsIn[1],
            this.state.statsIn[2],
            this.state.statsIn[3],
            this.state.statsIn[4],
            this.state.statsIn[5],
            this.state.statsIn[6],
            this.state.statsIn[7],
            this.state.statsIn[8],
            this.state.statsIn[9],
            this.state.statsIn[10],
            this.state.statsIn[11],
          ],
          fill: true,
          borderColor: "#51CACF",
          backgroundColor: "transparent",
          pointBorderColor: "#51CACF",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
      ],
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-10" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Employee</p>
                        <CardTitle tag="p">
                          {this.state.employees.length}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-briefcase-24" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Absence Employee Num</p>
                        <CardTitle tag="p">
                          {this.state.absence_today}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div> */}
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody >
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-touch-id" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">In Zone</p>
                        <CardTitle tag="p">
                          {this.state.employeesIn.length}
                        </CardTitle>

                        {/* <CardTitle tag="p" >{this.state.employees.length}</CardTitle> */}

                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div> */}
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-user-run" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Out Zone</p>
                        <CardTitle tag="p">
                          {this.state.employeesOut.length}
                        </CardTitle>

                        {/* <CardTitle tag="p" >{this.state.employees.length}</CardTitle> */}

                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {/* <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div> */}
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Users Behavior</CardTitle>
                <p className="card-category">24 Hours performance</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooter>
            </Card>
          </Col> */}
          </Row>
          <Row>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Daily Company Statistics</CardTitle>
                  {/* <p className="card-category">Last Campaign Performance</p> */}
                </CardHeader>
                <CardBody style={{ height: "266px" }}>
                  <Pie
                    data={data}
                    options={dashboardEmailStatisticsChart.options}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-primary" /> In Zone{" "}
                    {/* <i className="fa fa-circle text-warning" /> Read{" "} */}
                    <i className="fa fa-circle text-danger" /> Out Zone{" "}
                    <i className="fa fa-circle text-gray" /> Absence day
                  </div>
                  {/* <hr /> */}
                  <div className="stats">
                    {/* <i className="fa fa-calendar" /> Number of emails sent */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="8">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Monthly Company Statistics</CardTitle>
                </CardHeader>
                <CardBody>
                  <Line
                    data={lineData}
                    options={dashboardNASDAQChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <div className="chart-legend">
                    <i className="fa fa-circle text-info" /> Out of zone{" "}
                    <i className="fa fa-circle text-warning" /> In zone
                  </div>
                  <hr />
                  <div className="card-stats">
                    {/* <i className="fa fa-check" /> Total: */}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
