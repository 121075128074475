import { React, Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
});

class DepartmentTable extends Component {
  constructor(props) {
    super(props);
    api.get("/dep").then((res) => {
      console.log(res.data);
      this.setState({ department: res.data });
    });
  }
  state = { department: [] };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Department Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table striped>
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>Department Name</th>
                        <th>Arrival Time</th>
                        <th>Leave Time</th>
                        <th>Radius</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.department.map((department) => {
                        return (
                          <tr>
                            <td>{department.id}</td>
                            <td>{department.dep_name}</td>
                            <td>{department.const_Arrival_time}</td>
                            <td>{department.const_Leave_time}</td>
                            <td>{department.radius}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default DepartmentTable;
