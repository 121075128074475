import React, { Component } from 'react';
import axios from 'axios'
import "assets/Css-Own/adjustment.css"
import {
Button,
CardBody,
Form,
FormGroup,
Label,
Input
} from "reactstrap";

class Typography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overhead_name:'',
      overhead_role:'admin',
      employee_id:15,
      msg_text:'',


  }

  this.handleoverhead_nameChange = this.handleoverhead_nameChange.bind(this);
  this.handlemsg_textChange = this.handlemsg_textChange.bind(this);
}


handleoverhead_nameChange(e){
  this.setState({ 
    overhead_name:e.target.value,

 });

}
handlemsg_textChange(e){
  this.setState({ 
    msg_text:e.target.value,

 });
}


handleSubmit = event => {

  event.preventDefault();



  const api = axios.create({
  baseURL:'https://alexapparelsmobile.metatrackeregypt.com/api/',

  })
      api.post('msg', {
        overhead_name:this.state.overhead_name,
        msg_text:this.state.msg_text,
        overhead_role: this.state.overhead_role,
        employee_id: 15
        
          }

          
      ).then(res=> {
          console.log(res.data);
          this.setState({ messages: res.data });
          alert("your message is send");

      }).catch(error=>{
          console.log(error.response.data);
          alert("error in your message  ")
      })




}
// state = {  }
  render() { 
    return (  
        <div className='content'>
            <CardBody className='container-bg'>
                <Form container justify="center">
                    <FormGroup>
                      <br />
                        <Label for="exampleText">Enter the title</Label>
                        <Input type="text"
                        name="text"
                        id="exampleText"
                        value={this.state.overhead_name}
                        onChange={this.handleoverhead_nameChange}
                        style={{ width: "500px", height:"40px"}} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <br />
                        <Label for="exampleText">Enter your message</Label>
                        <Input type="textarea"
                        name="text"
                        id="exampleText"
                        value={this.state.msg_text}
                        onChange={this.handlemsg_textChange}
                        style={{ width: "500px", height:"300px"}} 
                        />
                    </FormGroup>
                  <Button
                  className="btn-round"
                  color="primary"
                  onClick={this.handleSubmit}
                  >
                  Send
                  </Button> 
              </Form>
            </CardBody>
        </div>
  );
}
}
// }

export default Typography;



            //   <Form container justify="center">
            //       {/* <label className="px-1" md="20">      */}
            //       {/* <input 
            //          style={{ width: "500px", height:"40px"}} 
            //         onChange={this.handleoverhead_nameChange}
            //         value={this.state.overhead_name}
            //         type="text" 
            //         placeholder='enter the overhead'
            //       /> */}
            //         <FormGroup>
            //             <Label for="exampleText">enter the overhead</Label>
            //             <Input type="text"
            //             name="text"
            //             id="exampleText"
            //             value={this.state.overhead_name}
            //             onChange={this.handleoverhead_nameChange}
            //             style={{ width: "500px", height:"40px"}} 
            //             />
            //         </FormGroup>
            //         <FormGroup>
            //             <Label for="exampleText">Enter your message</Label>
            //             <Input type="textarea"
            //             name="text"
            //             id="exampleText"
            //             value={this.state.msg_text}
            //             onChange={this.handlemsg_textChange}
            //             style={{ width: "500px", height:"40px"}} 
            //             />
            //         </FormGroup>
            //       <Button
            //       className="btn-round"
            //       color="primary"
            //       onClick={this.handleSubmit}
            //       >
            //       Send
            //       </Button> 
            //   </Form>