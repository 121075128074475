
import React, {Component} from "react"; 
import axios from 'axios'; 
import 'bootstrap/dist/css/bootstrap.css';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";



    
// export default class DeleteEmployee extends Component {  
//   state = {  
//     employees: []  
//   }  
    
//   componentDidMount() {  
//     axios.get(`https://metatrackeregypt.com/api`)  
//       .then(res => {  
//         const employees = res.data;  
//         this.setState({employees});  
//       })  
//   }  
    
//   deleteRow(id, e){  
//     axios.delete(`https://metatrackeregypt.com/api/${id}`)  
//       .then(res => {  
//         console.log(res);  
//         console.log(res.data);  
    
//         const employees = this.state.employees.filter(item => item.id !== id);  
//         this.setState({ employees });  
//       })  
    
//   }  
    
//   render() {  
//     return (  
//       <div>
//         <div className="content pt-5">
//         <Row>
//           <Col md="12">
//             <Card>
//               <CardHeader>
//                 <CardTitle tag="h4">Simple Table</CardTitle>
//               </CardHeader>  
          
    
//           <CardBody>
//                 <Table responsive>
//                   <thead className="text-primary">
//                     <tr>
//                       <th>Name</th>
//                       <th>Email</th>
//                       <th>Phone</th>
//                       <th>Gender</th>
//                       <th className="text-right">Position</th>
//                     </tr>
//                   </thead> 
    
//             <tbody>  
//               {this.state.employees.map(employees => {
//               return (  
//                 <tr>  
//                   <td>{employees.id}</td>  
//                   <td>{employees.name}</td>  
//                   <td>{employees.email}</td>
//                   <td>{employees.phone}</td> 
//                   <td>{employees.gender}</td> 
//                   <td>{employees.position}</td>   
//                   <td className="text-right">  
//                     <button className="btn btn-danger" onClick={(e) => this.deleteRow(employees.id, e)}>Delete</button>  
//                   </td>  
//                 </tr>);
//               })}
            
//             </tbody>
//           </Table>
//         </CardBody>
//       </Card>
//     </Col>
//   </Row>
            
//       </div> 
//       </div> 
    
          
//     );
//   }  
// }  









const api = axios.create({
    baseURL:'https://alexapparelsmobile.metatrackeregypt.com/api',

})



class Tables extends Component {
    constructor(props) {
        super(props);
        api.get('/get-employees').then(res=> {
            console.log(res.data);
            this.setState({ employees: res.data })
        })
        this.handleUpdateChange = this.handleUpdateChange.bind(this);



    }

     handleUpdateChange(id){
            console.log(id);
        }
    state = { 
        employees: []
     }

     handleClick = (id) => {
        // this.props.history.push("/admin/update/"+id);
        api.delete('/employees/'+id).then(res => {
          console.log("done")
          window.location.reload(false);
          this.props.history.push('admin/tables')
        })
    }
    render() { 
        return ( 
            <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Simple Table</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th className="text-right">delete employee</th>
                    </tr>
                  </thead>
                  <tbody>
                        {this.state.employees.map(employees=> {
                            return(      
                            <tr>
                                <td>{employees.name}</td>
                                <td>{employees.email}</td>
                                <td>{employees.phone}</td>
                                <td className="text-right"><button onClick={()=>{this.handleClick(employees.id)}}>delete </button></td>
                            </tr>);
                        })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
         );
    }
}
 
export default Tables;

