import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";

class AddAdmin extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      name: "",
      email: "",
      password: "",
      phone: "",
      is_Admin: "",
      is_Analyst: "",
      is_IT: "",
      is_HR: "",
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleis_AdminChange = this.handleis_AdminChange.bind(this);
    this.handleis_AnalystChange = this.handleis_AnalystChange.bind(this);
    this.handleis_ITChange = this.handleis_ITChange.bind(this);
    this.handleis_HRChange = this.handleis_HRChange.bind(this);
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handlePhoneChange(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleis_AdminChange(e) {
    this.setState({
      is_Admin: e.target.value,
    });
  }

  handleis_AnalystChange(e) {
    this.setState({
      is_Analyst: e.target.value,
    });
  }

  handleis_ITChange(e) {
    this.setState({
      is_IT: e.target.value,
    });
  }

  handleis_HRChange(e) {
    this.setState({
      is_HR: e.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const api = axios.create({
      baseURL: "https://metatrackeregypt.com/api",
    });
    api
      .post("/admin-register", {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        phone: this.state.phone,
        is_Admin: this.state.is_Admin,
        is_Analyst: this.state.is_Analyst,
        is_IT: this.state.is_IT,
        is_HR: this.state.is_HR,
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ admins: res.data });
        alert("admin added to the list.");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("please fill your form.");
      });
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Create Admin</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          placeholder="Name"
                          type="text"
                          onChange={this.handleNameChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Position</label>
                        <Input
                          placeholder="Position"
                          type="text"
                          onChange={this.handlePositionChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          placeholder="Email"
                          type="text"
                          onChange={this.handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <label>
                        <input
                          style={{ marginleft: 100 }}
                          type="radio"
                          value="Male"
                          checked={this.state.selectedOption === "Male"}
                          onChange={this.handleis_HRChange}
                        />
                        Male
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <Input
                          placeholder="password"
                          type="password"
                          onChange={this.handlePasswordChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Phone</label>
                        <Input
                          placeholder="Phone"
                          type="text"
                          onChange={this.handlePhoneChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    color="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Add Admin
                  </Button>
                </CardBody>
                {/* <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                         onChange={this.handleNameChange}
                          value={this.state.name}
                          placeholder="Admin name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                         onChange={this.handleEmailChange}
                          value={this.state.email}
                          placeholder="Email"
                          type="email"
                          
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>password</label>
                        <Input
                          onChange={this.handlePasswordChange}
                          placeholder="password"
                          type="password"
                         
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>phone</label>
                        <Input
                          placeholder="phone"
                          type="text"
                          onChange={this.handlePhoneChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>is_Admin</label>
                        <Input
                          onChange={this.handleis_AdminChange}
                          placeholder="is_Admin"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>is_Analyst</label>
                        <Input
                         onChange={this.handleis_AnalystChange}
                          placeholder="is_Analyst"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>is_IT</label>
                        <Input
                        onChange={this.handleis_ITChange}
                         placeholder="is_IT" type="text"
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>is_HR</label>
                        <Input
                        onChange={this.handleis_HRChange}
                         placeholder="is_HR"
                         type="text"
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        add Admin
                      </Button>
                    </div>
                  </Row>
                  </Form>
              </CardBody> */}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AddAdmin;
