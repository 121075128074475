import React, { Component } from "react";
import * as XLSX from "xlsx";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Form,
  Row,
  Col,
} from "reactstrap";
// import DropdownButton from "react-bootstrap/DropdownButton";

import axios from "axios";

class CreateEmployee extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      name: "",
      email: "",
      password: "",
      phone: 1,
      gender: "",
      Arrival_time: 0,
      Leave_time: 0,
      absence_day: 0,
      position: "",
      file: "",
      selectValue: 0,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleArriveltimeChange = this.handleArriveltimeChange.bind(this);
    this.handleLeavetimeChange = this.handleLeavetimeChange.bind(this);
    this.handleAbsencedayChange = this.handleAbsencedayChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    console.log(file);
    this.setState({ file });

    console.log(this.state.file);
  }

  readFile() {
    var f = this.state.file;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      const test = this.convertToJson(data);
      let emp = JSON.parse(test);
      let test2 = { emp };

      // let emp = [{"emp" : this.convertToJson(data)}];

      console.log("Data>>>" + data); // shows that excel data is read
      console.log(test2); // shows data in json format

      const api = axios.create({
        baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api/",
      });
      api
        .post("list-of-employees", { emp })
        .then((res) => {
          console.log(res.data);
          this.setState({ employees: res.data });
          alert("Excel added to the list");
        })
        .catch((error) => {
          console.log(error.response.data);
          alert("Wrong form");
        });
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handlePhoneChange(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleGenderChange(e) {
    this.setState({
      gender: e.target.value,
    });
  }

  handleArriveltimeChange(e) {
    this.setState({
      Arrival_time: e.target.value,
    });
  }

  handleLeavetimeChange(e) {
    this.setState({
      Leave_time: e.target.value,
    });
  }

  handleAbsencedayChange(e) {
    this.setState({
      absence_day: e.target.value,
    });
  }

  handlePositionChange(e) {
    this.setState({
      position: e.target.value,
    });
  }

  handleChange(e) {
    // console.log("Fruit Selected!!");
    this.setState({ dep_name: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const api = axios.create({
      baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
    });
    api
      .post("/register", {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        gender: this.state.gender,
        phone: this.state.phone,
        position: this.state.position,
        company_id: 1,
        department_id: 1,
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ employees: res.data });
        alert("Employee added to the list.");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("please fill your form.");
      });
  };
  render() {
    const options = [
      {
        label: "Information Technology",
        value: "Information Technology",
      },
      {
        label: "Information System",
        value: "Information System",
      },
    ];

    return (
      <>
        <div className="content">
          <Row>
            <Col md="8">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    Import employee from excel up to 1000 employee
                  </CardTitle>
                </CardHeader>
                <Form container justify="center">
                  <Button
                    style={{ marginLeft: 100 }}
                    className="btn-round"
                    color="primary"
                  >
                    <input
                      //  style={{ backgroundColor: '#417D7A' ,alignItems: "center",  marginVertical: 10,}}
                      type="file"
                      id="file"
                      ref="fileUploader"
                      onChange={this.filePathset.bind(this)}
                    />
                  </Button>
                  <Button
                    style={{ width: 170, height: 45 }}
                    className="btn-round"
                    color="primary"
                    onClick={() => {
                      this.readFile();
                    }}
                  >
                    Read file
                  </Button>
                  {/* <button
                    onClick={() => {
                      this.readFile();
                    }}
                  >
                    Read File
                  </button> */}
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h4>Employee Info</h4>
                </CardHeader>
                {/* <CardTitle>Employee Info</CardTitle> */}

                <CardBody>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          placeholder="Name"
                          type="text"
                          onChange={this.handleNameChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Position</label>
                        <Input
                          placeholder="Position"
                          type="text"
                          onChange={this.handlePositionChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          placeholder="Email"
                          type="text"
                          onChange={this.handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label for="cars">Change Department:</label>
                        <select
                          value={this.state.dep_name}
                          onChange={this.handleChange}
                        >
                          {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <Input
                          placeholder="password"
                          type="password"
                          onChange={this.handlePasswordChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Phone</label>
                        <Input
                          placeholder="Phone"
                          type="text"
                          onChange={this.handlePhoneChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    color="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Add Employee
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CreateEmployee;
