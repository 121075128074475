import { React, Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import { Doughnut, Bar } from "react-chartjs-2";

import axios from "axios";
import "assets/Css-Own/adjustment.css";
// import Admin from "layouts/Admin"

const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
});

class User extends Component {
  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleArriveltimeChange = this.handleArriveltimeChange.bind(this);
    this.handleLeavetimeChange = this.handleLeavetimeChange.bind(this);
    this.handleAbsencedayChange = this.handleAbsencedayChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    api.get("/calcYear/" + this.props.location.state.id).then((res) => {
      //   console.log(res.data);
      this.setState({ stats: res.data });
    });
  }
  state = {
    value: this.props.location.state,
    name: this.props.location.state.name,
    email: this.props.location.state.email,
    password: this.props.location.state.password,
    phone: this.props.location.state.phone,
    gender: this.props.location.state.gender,
    Arrival_time: this.props.location.state.Arrival_time,
    Leave_time: this.props.location.state.Leave_time,
    position: this.props.location.state.position,
    stats: [],
  };

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handlePhoneChange(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleGenderChange(e) {
    this.setState({
      gender: e.target.value,
    });
  }

  handleArriveltimeChange(e) {
    this.setState({
      Arrival_time: e.target.value,
    });
  }

  handleLeavetimeChange(e) {
    this.setState({
      Leave_time: e.target.value,
    });
  }

  handleAbsencedayChange(e) {
    this.setState({
      absence_day: e.target.value,
    });
  }

  handlePositionChange(e) {
    this.setState({
      position: e.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.email);
    const api = axios.create({
      baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
    });
    api
      .put("/employees/" + this.props.location.state.id, {
        email: this.state.email,
        gender: this.state.gender,
        phone: this.state.phone,
        Arrival_time: this.state.Arrival_time,
        Leave_time: this.state.Leave_time,
        //absence_day:this.state.absence_day,
        position: this.state.position,
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ employees: res.data });
        alert("Employee updated to the list.");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert(error.response.data);
      });
  };

  deleteEmployee = (event) => {
    event.preventDefault();
    const api = axios.create({
      baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
    });

    api.delete("/employees/" + this.props.location.state.id).then((res) => {});
  };

  render() {
    let jan = Object(this.state.stats[0]);
    let feb = Object(this.state.stats[1]);
    let mar = Object(this.state.stats[2]);
    let apr = Object(this.state.stats[3]);
    let may = Object(this.state.stats[4]);
    let jun = Object(this.state.stats[5]);
    let jul = Object(this.state.stats[6]);
    let aug = Object(this.state.stats[7]);
    let sep = Object(this.state.stats[8]);
    let oct = Object(this.state.stats[9]);
    let nov = Object(this.state.stats[10]);
    let dec = Object(this.state.stats[11]);
    console.log(this.state.stats);
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "total Hours",
          backgroundColor: "#EC932F",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            jan.totalDays1,
            feb.totalDays2,
            mar.totalDays3,
            apr.totalDays4,
            may.totalDays5,
            jun.totalDays6,
            jul.totalDays7,
            aug.totalDays8,
            sep.totalDays9,
            oct.totalDays10,
            nov.totalDays11,
            dec.totalDays12,
          ],
        },
        {
          label: "total absence",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            jan.absenceDay1,
            feb.absenceDay2,
            mar.absenceDay3,
            apr.absenceDay4,
            may.absenceDay5,
            jun.absenceDay6,
            jul.absenceDay7,
            aug.absenceDay8,
            sep.absenceDay9,
            oct.absenceDay10,
            nov.absenceDay11,
            dec.absenceDay12,
          ],
        },
      ],
    };
    return (
      <>
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Monthly Employee Statistics</CardTitle>
                <p className="card-category">for working and delay hours</p>
              </CardHeader>
              <CardBody>
                <Bar data={data} />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Opened
                  <i className="fa fa-circle text-warning" /> Read
                  <i className="fa fa-circle text-danger" /> Deleted
                  <i className="fa fa-circle text-gray" /> Unopened
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Number of emails sent
                </div>
              </CardFooter>
            </Card>
          </Col>

          <div className="content ">
            <Row>
              <Col md="20">
                <Card className="card-user">
                  <CardHeader>
                    <CardTitle tag="h5" className="centered">
                      Update employee
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row className="centered">
                        <Col className="pr-1" md="5">
                          <FormGroup>
                            <label>ID</label>
                            <Input
                              defaultValue={this.props.location.state.id}
                              disabled
                              placeholder="Company"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-1" md="5">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              defaultValue={this.props.location.state.name}
                              placeholder="Arrivel time"
                              type="text"
                              onChange={this.handleArriveltimeChange}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col className="pl-1" md="2">

                    </Col> */}
                      </Row>
                      <Row className="centered">
                        <Col className="pr-1" md="5">
                          <FormGroup>
                            <label>Email</label>
                            <Input
                              defaultValue={this.props.location.state.email}
                              placeholder="Email"
                              type="text"
                              onChange={this.handleEmailChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="5">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">Password</label>
                            <Input placeholder="password" type="email" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="centered">
                        <Col className="pr-1" md="5">
                          <FormGroup>
                            <label>Phone</label>
                            <Input
                              defaultValue={this.props.location.state.phone}
                              placeholder="Phone"
                              type="text"
                              onChange={this.handleArriveltimeChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="5">
                          <FormGroup>
                            <label>Position</label>
                            <Input
                              defaultValue={this.props.location.state.position}
                              placeholder="Position"
                              type="text"
                              onChange={this.handlePositionChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="centered">
                        <div className="update ml-auto mr-auto">
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            Update Employee
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Row>
      </>
    );
  }
}

export default User;
