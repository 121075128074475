import { React, Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { Bar } from "react-chartjs-2";
import { GoogleMap } from "@react-google-maps/api";

import axios from "axios";
const api = axios.create({
  baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
});

// import "assets/Css-Own/adjustment.css";

class Users extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleArriveltimeChange = this.handleArriveltimeChange.bind(this);
    this.handleLeavetimeChange = this.handleLeavetimeChange.bind(this);
    this.handleAbsencedayChange = this.handleAbsencedayChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    // this.toggleNavbar = this.toggleNavbar.bind(this);
    api.get("/calcYear/" + this.props.location.state.id).then((res) => {
      //   console.log(res.data);
      this.setState({ stats: res.data });
    });
    api.get("/currentlocation/" + this.props.location.state.id).then((res) => {
      //   console.log(res.data);
      this.setState({ currentLocation: res.data });
    });
    api.get("/calcOutEmp/" + this.props.location.state.id).then((res) => {
      //   console.log(res.data);
      this.setState({ employeesOut: res.data });
    });
    api.get("/calcInEmp/" + this.props.location.state.id).then((res) => {
      //   console.log(res.data);
      this.setState({ employeesIn: res.data });
    });
  }
  state = {
    cond: true,
    value: this.props.location.state,
    name: this.props.location.state.name,
    email: this.props.location.state.email,
    password: this.props.location.state.password,
    phone: this.props.location.state.phone,
    gender: this.props.location.state.gender,
    Arrival_time: this.props.location.state.Arrival_time,
    Leave_time: this.props.location.state.Leave_time,
    position: this.props.location.state.position,
    stats: [],
    currentLocation: [],
    employeesIn: [],
    employeesOut: [],
    dep_name: "",
  };
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handlePhoneChange(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleGenderChange(e) {
    this.setState({
      gender: e.target.value,
    });
  }

  handleArriveltimeChange(e) {
    this.setState({
      Arrival_time: e.target.value,
    });
  }

  handleLeavetimeChange(e) {
    this.setState({
      Leave_time: e.target.value,
    });
  }

  handleAbsencedayChange(e) {
    this.setState({
      absence_day: e.target.value,
    });
  }

  handlePositionChange(e) {
    this.setState({
      position: e.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const api = axios.create({
      baseURL: "https://alexapparelsmobile.metatrackeregypt.com/api",
    });
    api
      .put(
        "/employees/" + this.props.location.state.id,
        {
          email: this.state.email,
          gender: this.state.gender,
          phone: this.state.phone,
          position: this.state.position,
          dep_name: this.state.dep_name,

          // password: this.state.password,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        this.setState({ employees: res.data });
        alert("Employee updated to the list.");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("Please select department to update");
      });
  };

  handleResetLogin = (event) => {
    event.preventDefault();
    api
      .put("/is_here/" + this.props.location.state.id)
      .then((res) => alert("Employee login reset"));
  };

  handleChange(e) {
    // console.log("Fruit Selected!!");
    this.setState({ dep_name: e.target.value });
  }

  handleClick = () => {
    // this.props.history.push("/admin/update/"+id);
    api.delete("/employees/" + this.props.location.state.id).then((res) => {
      window.location.reload(true);
      //   console.log(res);
    });
  };
  render() {
    let jan = Object(this.state.stats[0]);
    let feb = Object(this.state.stats[1]);
    let mar = Object(this.state.stats[2]);
    let apr = Object(this.state.stats[3]);
    let may = Object(this.state.stats[4]);
    let jun = Object(this.state.stats[5]);
    let jul = Object(this.state.stats[6]);
    let aug = Object(this.state.stats[7]);
    let sep = Object(this.state.stats[8]);
    let oct = Object(this.state.stats[9]);
    let nov = Object(this.state.stats[10]);
    let dec = Object(this.state.stats[11]);

    const options = [
      {
        label: "Information Technology",
        value: "Information Technology",
      },
      {
        label: "Information System",
        value: "Information System",
      },
    ];

    // let currentLocation = this.state.currentLocation;
    // console.log(currentLocation);
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "total Hours",
          backgroundColor: "#EC932F",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            jan.totalDays1,
            feb.totalDays2,
            mar.totalDays3,
            apr.totalDays4,
            may.totalDays5,
            jun.totalDays6,
            jul.totalDays7,
            aug.totalDays8,
            sep.totalDays9,
            oct.totalDays10,
            nov.totalDays11,
            dec.totalDays12,
          ],
        },
        {
          label: "total absence",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            jan.absenceDay1,
            feb.absenceDay2,
            mar.absenceDay3,
            apr.absenceDay4,
            may.absenceDay5,
            jun.absenceDay6,
            jul.absenceDay7,
            aug.absenceDay8,
            sep.absenceDay9,
            oct.absenceDay10,
            nov.absenceDay11,
            dec.absenceDay12,
          ],
        },
      ],
    };

    const dataZone = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "total in Zone",
          backgroundColor: "#EC932F",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            this.state.employeesIn[0],
            this.state.employeesIn[1],
            this.state.employeesIn[2],
            this.state.employeesIn[3],
            this.state.employeesIn[4],
            this.state.employeesIn[5],
            this.state.employeesIn[6],
            this.state.employeesIn[7],
            this.state.employeesIn[8],
            this.state.employeesIn[9],
            this.state.employeesIn[10],
            this.state.employeesIn[11],
          ],
        },
        {
          label: "total Out of zone",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [
            this.state.employeesOut[0],
            this.state.employeesOut[1],
            this.state.employeesOut[2],
            this.state.employeesOut[3],
            this.state.employeesOut[4],
            this.state.employeesOut[5],
            this.state.employeesOut[6],
            this.state.employeesOut[7],
            this.state.employeesOut[8],
            this.state.employeesOut[9],
            this.state.employeesOut[10],
            this.state.employeesOut[11],
          ],
        },
      ],
    };
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5>Employee Display Picture</h5>
                </CardHeader>
                <CardBody>
                  <Col>
                    <img
                      alt="User"
                      src={
                        "https://st2.depositphotos.com/5682790/10456/v/450/depositphotos_104564156-stock-illustration-male-user-icon.jpg"
                      }
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col xs={6}>
              <Card>
                <CardHeader>
                  <h4>Employee Info</h4>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          defaultValue={this.props.location.state.name}
                          placeholder="Name"
                          type="text"
                          onChange={this.handleNameChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Position</label>
                        <Input
                          defaultValue={this.props.location.state.position}
                          placeholder="Position"
                          type="text"
                          onChange={this.handlePositionChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          defaultValue={this.props.location.state.email}
                          placeholder="Email"
                          type="text"
                          onChange={this.handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Created at</label>
                        <Input
                          defaultValue={this.props.location.state.created_at}
                          placeholder="Created at"
                          type="text"
                          onChange={this.handleEmailChange}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Password</label>
                        <Input placeholder="password" type="password" />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label for="cars">Change Department:</label>
                        <select
                          value={this.state.dep_name}
                          onChange={this.handleChange}
                        >
                          {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Phone</label>
                        <Input
                          defaultValue={this.props.location.state.phone}
                          placeholder="Phone"
                          type="text"
                          onChange={this.handlePhoneChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    color="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Update Employee
                  </Button>
                  <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    color="danger"
                    type="submit"
                    onClick={this.handleClick}
                  >
                    Delete Employee
                  </Button>
                  <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    // color="yellow"
                    type="submit"
                    onClick={this.handleResetLogin}
                  >
                    Reset Login
                  </Button>

                  {/* <Button
                    //   className="btn-round pr-3 "
                    className="pad"
                    // color="yellow"
                    type="submit"
                    onClick={() => {
                      console.log(this.state.dep_name);
                    }}
                  >
                    Reset Login
                  </Button> */}
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="t-2">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{ lat: 31.2282494, lng: 29.9506433 }}
                  zoom={15}
                  onLoad={this.onLoad}
                  onUnmount={this.onUnmount}
                >
                  {/* {this.state.currentLocation.map((employee) => {
                    return (
                      <Marker
                        key={employee.id}
                        position={{
                          lat: parseFloat(employee.lat),
                          lng: parseFloat(employee.lng),
                        }}
                        title={employee.employee.name}
                      />
                    );
                  })} */}
                </GoogleMap>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Monthly Employee Statistics</CardTitle>
                  <p className="card-category">
                    calculating the zone out and in
                  </p>
                </CardHeader>
                <CardBody>
                  <Bar data={data} />
                </CardBody>
              </Card>
            </Col>
            <Col sm={4}>
              {" "}
              {/* <Card>
                <CardHeader>
                  <CardTitle tag="h5">Monthly Employee Statistics</CardTitle>
                  <p className="card-category">for working and delay hours</p>
                </CardHeader>
                <CardBody>
                  <Bar data={data} />
                </CardBody>
              </Card> */}
            </Col>
            <Col>
              {" "}
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Monthly Zone Statistics</CardTitle>
                  <p className="card-category">Time of the employee absence</p>
                </CardHeader>
                <CardBody>
                  <Bar data={dataZone} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;

const containerStyle = {
  width: "500px",
  height: "450px",
  //   marginLeft: '100px',
  //   marginTop: '100px',
};
